import { createWebHistory, createRouter } from "vue-router";

//import WebsiteAnalyticsPage from "../components/Pages/WebsiteAnalyticsPage";
//import MyDevicesPage from "../components/Pages/MyDevicesPage";
import Applications from "../components/Pages/ApplicationsPage";

import Companies from "../components/Pages/CompaniesPage";
import Countries from "../components/Pages/CountriesPage";
import Users from "../components/Pages/UsersPage";

import LoginPage from "../components/Pages/LoginPage";
import ProfilePage from "../components/Pages/ProfilePage";
import ForgetPasswordPage from "../components/Pages/ForgetPasswordPage";
import ChangePasswordPage from "../components/Pages/ChangePasswordPage";
import ScreenLockPage from "../components/Pages/ScreenLockPage";
import ErrorPage from "../components/Pages/ErrorPage";

const ifAuthenticated = async (to, from, next) => {
  if (localStorage.getItem('accessToken') && (localStorage.getItem('accessToken') !== null) && localStorage.getItem('accessToken').length) {
    next()
    return
  }
  next('/login')
}


const routes = [
  //{ path: "/", name: "WebsiteAnalyticsPage", component: WebsiteAnalyticsPage },
 
  { path: "/",
    name: "applications",
    beforeEnter: ifAuthenticated,
    component: Applications },
 
  { path: "/companies", 
    name: "companies",
    beforeEnter: ifAuthenticated,
    component: Companies },
    
  { path: "/countries",
    name: "countries", 
    beforeEnter: ifAuthenticated,
    component: Countries },

  { path: "/users",
   name: "users",
   beforeEnter: ifAuthenticated,
   component: Users },

  

  { path: "/profile",
    name: "ProfilePage", 
    beforeEnter: ifAuthenticated,
    component: ProfilePage },
  
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/forget-password", name: "ForgetPasswordPage", component: ForgetPasswordPage, },
  { path: "/change-password",name: "ChangePasswordPage",component: ChangePasswordPage,},
  { path: "/screen-lock", name: "ScreenLockPage", component: ScreenLockPage },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
 
 
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
