/* // axios 
import axios from 'axios'
axios.defaults.baseURL = 'https://transvieportail.moozistudio.com/api/'

//axios.defaults.baseURL = 'http://51.38.57.172:9033/api/'
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`

export default axios.create({}) */
import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://transvieportail.moozistudio.com/api/'
})

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

export default apiClient